import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Lottie from 'react-lottie';

import { Container, Hero, KnowYou, Footer } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import InputCheckbox, { IOption } from '~/components/InputCheckbox';

import logo from '~/assets/logos/logo.svg';
import details from '~/assets/defaults/details.svg';
import cellsLogo from '~/assets/logos/cells-logo.svg';
import gift from '~/assets/defaults/gift.png';
import churchIcons from '~/assets/icons/church-icons.svg';
import check from '~/assets/animations/check.json';
import { useResize } from '~/hooks/Resize';

const Home: React.FC = () => {
  const { width } = useResize();
  const formRef = useRef<FormHandles>(null);
  const [isInCell, setIsInCell] = useState(false);
  const [step, setStep] = useState(1);

  const handleChangeIsInCell = useCallback((options: IOption[]) => {
    const optionSeleted = options.find((option) => option.selected);

    if (optionSeleted) {
      setIsInCell(optionSeleted.value === 'yes');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          birthdate: Yup.string().required(
            'A data de nascimento é obrigatória'
          ),
          phone: Yup.string().required('O telefone é obrigatório'),
          cellName: Yup.string().when('$cellNameCheck', {
            is: (cellNameCheck: boolean) => !cellNameCheck,
            then: Yup.string().required('O nome da célula é obrigatório'),
            otherwise: Yup.string(),
          }),
          zipcode: Yup.string().required('O cep é obrigatório'),
          street: Yup.string().required('A rua é obrigatória'),
          number: Yup.string().required('O número é obrigatório'),
          neighborhood: Yup.string().required('O bairro é obrigatório'),
          city: Yup.string().required('A cidade é obrigatória'),
          state: Yup.string().required('O estado é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: { cellNameCheck: !isInCell },
        });

        const {
          name,
          birthdate,
          phone,
          cellName,
          zipcode,
          street,
          number,
          neighborhood,
          city,
          state,
          complement,
        } = data;

        const formData = {
          name,
          birthdate,
          phone,
          cellName,
          zipcode,
          street,
          number,
          neighborhood,
          city,
          state,
          complement,
        };

        await api.post('leads', formData);

        setStep(3);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);

          const errorsKeys = Object.keys(errors);

          if (
            errorsKeys.find(
              (errorKey) =>
                errorKey === 'name' ||
                errorKey === 'birthdate' ||
                errorKey === 'phone' ||
                errorKey === 'cellName'
            )
          ) {
            setStep(1);
          }

          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente por favor',
            'error'
          );
        }
      }
    },
    [isInCell]
  );

  const handleChangeZipCode = useCallback(async (e) => {
    if (e.target.value.length === 9) {
      const { data } = await api.get(
        `https://viacep.com.br/ws/${e.target.value}/json`
      );
      formRef.current?.setFieldValue('street', data.logradouro);
      formRef.current?.setFieldValue('neighborhood', data.bairro);
      formRef.current?.setFieldValue('city', data.localidade);
      formRef.current?.setFieldValue('state', data.uf);
    }
  }, []);

  return (
    <Container>
      <Hero className="d-flex align-items-end py-4">
        <div className="container pb-lg-3 pb-xl-5">
          <div className="row align-items-end justify-content-center">
            <div className="col-12">
              <div className="green-box">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h1 className="text-white fs-6 fs-lg-2 display-xl-3 fw-medium mb-0">
                      Seja Bem-vindo!
                    </h1>
                  </div>
                  <div className="col-4">
                    <img
                      src={logo}
                      alt="Logo"
                      className="logo d-block ms-auto"
                    />
                  </div>
                </div>
                <img src={details} alt="details" className="details" />
              </div>
            </div>
          </div>
        </div>
      </Hero>
      <KnowYou className="pb-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Form ref={formRef} onSubmit={handleSubmit} className="bg-green">
                {step !== 3 ? (
                  <>
                    <div className="row">
                      <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                        <img
                          src={cellsLogo}
                          alt="logo celulas"
                          className="cells-logo"
                        />
                        <h2 className="fs-4 fs-lg-3 fs-xl-1 fw-normal text-white">
                          Queremos te conhecer
                        </h2>
                      </div>
                    </div>
                    <div
                      className={`row mt-5 pt-3 ${step !== 1 ? 'd-none' : ''}`}
                    >
                      <div className="col-md-6 col-lg-4 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            Nome Completo
                          </span>
                          <Input
                            name="name"
                            className="input"
                            placeholder="Digite aqui"
                          />
                        </label>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            Data de nascimento
                          </span>
                          <Input
                            type="date"
                            name="birthdate"
                            className="input"
                          />
                        </label>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            Telefone
                          </span>
                          <InputMask
                            kind="cel-phone"
                            name="phone"
                            className="input"
                            placeholder="(__) _____-____"
                          />
                        </label>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-3">
                            Participa de alguma célula?
                          </span>
                        </label>
                        <InputCheckbox
                          type="radio"
                          name="isInCell"
                          options={[
                            { label: 'Sim', value: 'yes', selected: isInCell },
                            { label: 'Não', value: 'no', selected: !isInCell },
                          ]}
                          onChange={handleChangeIsInCell}
                          className="justify-content-start pt-1"
                        />
                      </div>
                      {isInCell && (
                        <div className="col-lg-4 mb-3">
                          <label className="d-block w-100">
                            <span className="d-block w-100 text-white mb-2">
                              Qual?
                            </span>
                            <Input
                              name="cellName"
                              className="input"
                              placeholder="Digite aqui"
                            />
                          </label>
                        </div>
                      )}
                    </div>
                    <div
                      className={`row mt-5 pt-lg-3 ${
                        step !== 2 ? 'd-none' : ''
                      }`}
                    >
                      <div className="col-md-6 col-lg-3 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            CEP
                          </span>
                          <InputMask
                            kind="zip-code"
                            name="zipcode"
                            className="input"
                            placeholder="Digite aqui"
                            onChange={handleChangeZipCode}
                          />
                        </label>
                      </div>
                      <div className="col-md-6 col-lg-6 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            Rua
                          </span>
                          <Input
                            name="street"
                            className="input"
                            placeholder="Digite aqui"
                          />
                        </label>
                      </div>
                      <div className="col-md-6 col-lg-3 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            Número
                          </span>
                          <Input
                            name="number"
                            className="input"
                            placeholder="Digite aqui"
                          />
                        </label>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            Bairro
                          </span>
                          <Input
                            name="neighborhood"
                            className="input"
                            placeholder="Digite aqui"
                          />
                        </label>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            Cidade
                          </span>
                          <Input
                            name="city"
                            className="input"
                            placeholder="Digite aqui"
                          />
                        </label>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            Estado
                          </span>
                          <Input
                            name="state"
                            className="input"
                            placeholder="Digite aqui"
                          />
                        </label>
                      </div>
                      <div className="col-12 mb-3">
                        <label className="d-block w-100">
                          <span className="d-block w-100 text-white mb-2">
                            Complemento
                          </span>
                          <Input
                            name="complement"
                            className="input"
                            placeholder="Digite aqui"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-center mt-5">
                      <div className="col-lg-4 mb-3 d-flex align-items-center justify-content-center">
                        {step === 2 && (
                          <>
                            <button
                              type="button"
                              className="btn btn-white h4 rounded-pill px-4 px-lg-5 py-2 me-3"
                              onClick={() => setStep(1)}
                            >
                              Voltar
                            </button>
                            <button
                              type="submit"
                              className="btn btn-white h4 rounded-pill px-4 px-lg-5 py-2"
                            >
                              Enviar
                            </button>
                          </>
                        )}
                        {step === 1 && (
                          <button
                            type="button"
                            className="btn btn-white h4 rounded-pill px-4 px-lg-5 py-2"
                            onClick={() => setStep(2)}
                          >
                            Continuar
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                      <div className="pe-none">
                        <Lottie
                          options={{
                            animationData: check,
                            autoplay: true,
                            loop: false,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          width={width >= 992 ? 274 : 218.4}
                          height={width >= 992 ? 271 : 215.35}
                          speed={0.5}
                        />
                      </div>
                      <h3 className="fs-6 fs-lg-4 fw-semibold text-white text-center">
                        Obrigado! Em breve entraremos em{' '}
                        <br className="d-none d-sm-block" />
                        contato com você
                      </h3>

                      <div className="green-box d-flex flex-column align-items-center justify-content-center">
                        <img src={gift} alt="Mimo" className="gift" />
                        <p className="h6 text-white">
                          Temos um mimo para você!
                        </p>
                        <p className="mb-0 text-center">
                          Procure alguém da equipe Connect, para poder te
                          entregar
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      </KnowYou>
      <Footer>
        <div className="container py-5">
          <div className="row align-items-end justify-content-center">
            <div className="col-12">
              <div className="green-box">
                <div className="row align-items-end">
                  <div className="col-sm-8">
                    <img
                      src={churchIcons}
                      alt="4 Pilares da quadrangular"
                      className="icons"
                    />
                    <h3 className="fs-6 fs-lg-3 text-white fw-light mt-5 mb-0">
                      Uma igreja, <br />
                      Um propósito, Uma visão
                    </h3>
                  </div>
                  <div className="col-sm-4 d-none d-sm-block">
                    <img
                      src={logo}
                      alt="Logo"
                      className="d-block ms-auto w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Footer>
    </Container>
  );
};

export default Home;
