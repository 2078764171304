import styled from 'styled-components';

export const Container = styled.div`
  background-color: #1b2721;
`;

export const Hero = styled.div`
  .green-box {
    background-color: #2fb26c;
    border-radius: 18px;
    padding: 25px 20px;
    overflow: hidden;
    position: relative;

    .logo {
      width: 76.907px;
      height: 17.963px;
    }

    .details {
      width: 197.375px;
      height: 81.607px;
      position: absolute;
      right: -95px;
      top: 0px;
    }
  }

  @media screen and (min-width: 992px) {
    .green-box {
      border-radius: 24px;
      padding: 33px 45px;

      .logo {
        width: 169.89px;
        height: 39.68px;
      }

      .details {
        width: 313.76px;
        height: 129.73px;
        right: -110px;
        top: 0px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .green-box {
      border-radius: 38px;
      padding: 79px 96px;

      .logo {
        width: unset;
        height: unset;
      }

      .details {
        width: unset;
        height: unset;
        right: -290px;
        top: -20px;
      }
    }
  }
`;

export const KnowYou = styled.div`
  .bg-green {
    background-color: #2a3a32;
    padding: 49px 36px 55px;
    border-radius: 16px;

    .cells-logo {
      background-color: #2fb26c;
      border-radius: 8px;
      padding: 6.88px 6.19px;
      margin-bottom: 30px;
      width: 42.254px;
      height: 42.254px;
    }

    .input {
      border: 1px solid #828282;
      background-color: #2a3a32;
      border-radius: 7px;

      input {
        color: #fff;

        ::placeholder {
          color: #d2d2d2;
        }
      }
    }

    .btn-white {
      background-color: #fff;
      color: #202020;
    }

    .green-box {
      margin: 30px -20px 0;
      background-color: #2f5d46;
      border-radius: 16px;
      padding: 0 10px 38px;

      .gift {
        margin-top: -20px;
        margin-bottom: 20px;
        padding: 13px 16px;
        border-radius: 50%;
        background-color: #feb61a;
      }

      p:last-child {
        color: #95bfa9;
        font-size: 14px;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .bg-green {
      .green-box {
        margin: 81px unset unset;

        p:last-child {
          padding: 0 50px;
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .bg-green {
      padding: 48px 60px 40px;
      border-radius: 24px;

      .cells-logo {
        border-radius: 18px;
        padding: 17px 15px;
        margin-bottom: 24px;
        width: unset;
        height: unset;
      }

      .green-box {
        padding: 0 157px 30px;

        .gift {
          margin-top: -30px;
          padding: 13px 16px;
          border-radius: 50%;
        }

        p:last-child {
          padding: unset;
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .bg-green {
      padding: 110px 131px 35px;
      border-radius: 38px;

      .cells-logo {
        border-radius: 18px;
        padding: 17px 15px;
        margin-bottom: 55px;
        width: unset;
        height: unset;
      }

      .green-box {
        padding: 0 157px 30px;

        .gift {
          margin-top: -30px;
          padding: 13px 16px;
          border-radius: 50%;
        }

        p:last-child {
          font-size: 16px;
        }
      }
    }
  }
`;

export const Footer = styled.div`
  .green-box {
    background-color: #101010;
    border-radius: 16px;
    padding: 30px;

    .icons {
      width: 128.484px;
      height: 22.738px;
    }
  }

  @media screen and (min-width: 992px) {
    .green-box {
      border-radius: 38px;
      padding: 72px 67px 30px;

      .icons {
        width: unset;
        height: unset;
      }
    }
  }
`;
